import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './pages/ErrorPage';
import SchoolPage from './pages/SchoolPage';
import Presentation from './pages/Presentation';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

const router = createBrowserRouter([
  {
    path:"/",
    element: <App />,
    errorElement: <ErrorPage />
  },
  {
    path:'/presentation',
    element: <Presentation />
  }, 
  {
    path: '/etablissement/:name',
    element: <SchoolPage />
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        cookieName="ConsentCookiesPromsocDays"
        style={{ background: "#2c2d59", zIndex: 2147483647 }}
        buttonStyle={{ background: "#ffffff", color: "#2c2d59", fontSize: "13px" }}
        expires={150}
      >
        Ce site utilise des cookies essentiels et fonctionnels nécessaires au bon fonctionnement du site Internet et ne peuvent pas être refusés.. En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de ceux-ci.
      </CookieConsent>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
