import Header from "../components/Header";
import Footer from "../components/Footer";
import { schools } from "../schools";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

const SchoolPage = (props) => {
    let {name} = useParams()
    const [School, setSchool] = useState({})
    const navigate = useNavigate()

    useEffect(()=>{
        window.scrollTo(0,0);
        if(!schools[name]){
            navigate("/error", {replace: true})
        }else{
            setSchool(schools[name])
        }
    },[])

    return ( 
        <>
            <div className="slide" id="school">
                <Header />
            <div className="banner">
                <h1>{School.nom}</h1>
            </div>
                <div className="wrapper">
                    <div className="school-logo">
                        <img src={`/images/${School.logo}`} alt={`Logo de ${School.nom}`} />
                    </div>
                    <div className="school-info">
                        <div className="title">Informations</div>
                        <div className="adresse">{School.adresse} - {School.cp} {School.ville}</div>
                        <div className="tel">Téléphone: {School.tel}</div>
                        <div className="mail">Mail: {School.email}</div>
                        <div className="description" dangerouslySetInnerHTML={{__html: School.description}}></div>
                        <div className="site">Site Web: <a href={School.url} target="_blank" rel="noopener noreferrer">{School.url}</a></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
     );
}
 
export default SchoolPage;