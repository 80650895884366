import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from 'react-router-dom'
const ErrorPage = (props) => {
    return ( 
        <>
        <div className="slide" id="error-page">
            <Header />
            <div className="banner">
                <p>DÉSOLÉ...</p>
                
            </div>
            <div className="wrapper">
            <h3>Cette page n’existe pas (ou plus).</h3>
                <Link to='/' className="btn blue">Retour</Link>
            </div>

        </div>

        <Footer />
        </>
     );
}
 
export default ErrorPage;