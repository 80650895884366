import { useEffect } from "react";
import { HashLink } from 'react-router-hash-link';
import Header from "../components/Header";
import Footer from "../components/Footer";

const Presentation = (props) => {
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return ( 
        <>
            <div className="slide" id="presentation-page">
                <Header />
                <div className="banner">
                    <div className="wrapper">
                        <h1>L’enseignement de promotion sociale en quelques mots</h1>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="pres-info">
                        <blockquote cite="http://www.enseignement.be/index.php?page=27151">
                            <p><strong>L’enseignement de promotion sociale (EPS)</strong> s’inscrit dans la dynamique de l’éducation tout au long de la vie en Communauté française.</p>
                            <p>Il offre aux adultes, aux parcours très diversifiés, un large éventail de formations de niveau enseignement secondaire ou supérieur, au terme desquelles il délivre des titres reconnus (certificats et diplômes). Grâce à la souplesse de son organisation (modularité, horaires adaptés, etc), l’EPS permet de cumuler études et emploi.</p>
                            <p>Il répond à des besoins individuels et collectifs variés : initiation, qualification, perfectionnement, recyclage, reconversion, spécialisation, épanouissement personnel.</p>
                            <p>
                                L’enseignement de promotion sociale poursuit deux finalités :
                            </p>
                            <ul>
                                <li>Concourir à l’épanouissement personnel et professionnel en promouvant une meilleure insertion sociale, professionnelle, scolaire et culturelle ;</li>
                                <li>Répondre aux besoins et aux demandes en formation émanant des entreprises, des administrations, de l’enseignement et, de manière générale, des milieux socio-économiques et culturels.</li>
                            </ul>
                            <p className="source">source: <cite>enseignement.be</cite></p>
                        </blockquote>
                        <h2>PromSoc Week</h2>
                        <p>Chaque année, dans le courant du mois d’avril, les établissements de promotion sociale de la zone Wallonie Picarde, sous l’égide de leur Commission sous-régionale, la <strong>CSR WaPi</strong>, se réunissent pour mettre à l’honneur l’<strong>enseignement de promotion sociale</strong> et faire connaître l’offre riche et variée de formations qu’ils dispensent : cet évènement est connu sous le nom de <strong>PromSoc Week.</strong></p>
                        <p>L'organisation conjointe de cet événement et la collaboration, à cette fin, entre établissements de différents pouvoirs organisateurs est également la preuve de l'esprit qui anime l'enseignement de Promotion sociale dans son ensemble.</p>
                        <p>Cette collaboration garantit la capacité de proposer à celles et ceux qui souhaitent s’inscrire en promotion sociale une offre riche et variée, ainsi qu'un parcours d'accompagnement et d'épanouissement personnel, quelle que soit la formation qu’ils choisissent.</p>
                        <p className="strong">Pour en savoir plus sur les formations spécifiques proposées par les établissements de la CSR WaPi, nous vous invitons à cliquer sur les liens relatifs à chacun d’eux.</p>
                        <p>Consulter l'ensemble des évènements Promsoc Week en Wallonie et à Bruxelles : <a href="http://enseignement.be/index.php?page=25703&ne_id=7309&fbclid=IwAR24Xh3K_gDjkfpM6A0gHBb8DBmAbHSPzSijEHo-X9Z2-9yji8j7m4uIp8Q" target="_blank">enseignement.be/promsocdays</a></p>
                        <div className="link">
                            <HashLink to='/#schools' className="btn blue">Voir les établissements</HashLink>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
     );
}
 
export default Presentation;